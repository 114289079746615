import {Link} from 'gatsby';
import * as React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import './menu.scss';
import {LinkGetProps} from '@reach/router';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export type MenuLink = {
  name: string;
  link: string;
}

export type MenuProps = {
  menuOpen: boolean
  links: MenuLink[]
  onClose?: () => void
}

export const Menu = ({ menuOpen, links, onClose }: MenuProps) => {
  const { t } = useTranslation();
  const whatLocation = ({ href, location }: LinkGetProps) => {
    if (location.pathname.replace('/en', '').includes(href)) {
      return { className: 'active' };
    }
    return {};
  };

  return <div className={menuOpen ? 'navigation navigation--open' : 'navigation'}>
    <button className={'close'} onClick={onClose}>
      <FontAwesomeIcon icon={'close'}/>
    </button>
    <nav>
      <ul>
        {links.map((link) => (
          <li
            key={link.name}
          >
            <Link to={link.link} getProps={whatLocation} activeClassName="active">
              {t(link.name)}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  </div>;
};
