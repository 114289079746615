import React from 'react';
import '../../assets/icons/font-awesome';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next';
// @ts-ignore
import HomeyIcon from './../../assets/icons/homey.svg';
import './footer.scss';

export default function Footer() {
  const {t} = useTranslation();
  return (
    <footer className={'footer'}>
      <section className={'socials'}>
        <h2><span>{t('contact')}</span></h2>
        <ul>
          <li>
            <a className="icon" href="mailto:leendert@koktail.nl" target={'_blank'} rel={'noreferrer'}>
              <FontAwesomeIcon icon={'envelope'}/>
            </a>
          </li>
          <li>
            <a className="icon" href="https://www.linkedin.com/in/leendert-de-kok" target={'_blank'} rel={'noreferrer'}>
              <FontAwesomeIcon icon={['fab', 'linkedin']}/>
            </a>
          </li>
          <li>
            <a className="icon" href="https://www.github.com/koktaildotcom" target={'_blank'} rel={'noreferrer'}>
              <FontAwesomeIcon icon={['fab', 'github']}/>
            </a>
          </li>
          <li>
            <a className="icon" href="https://www.instagram.com/koktail_nl" target={'_blank'} rel={'noreferrer'}>
              <FontAwesomeIcon icon={['fab', 'instagram']}/>
            </a>
          </li>
          <li>
            <a className="icon" href="https://twitter.com/koktail" target={'_blank'} rel={'noreferrer'}>
              <FontAwesomeIcon icon={['fab', 'twitter']}/>
            </a>
          </li>
          <li>
            <a className="icon" href="https://homey.app/nl-nl/apps/author/5a59cf004445b170349ed877/page/0" target={'_blank'} rel={'noreferrer'}>
              <HomeyIcon />
            </a>
          </li>
        </ul>
      </section>
    </footer>
  );
}

