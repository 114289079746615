import {library} from '@fortawesome/fontawesome-svg-core';
import {faClose, faEnvelope, faQuestion, faStar} from '@fortawesome/free-solid-svg-icons';
import {
  faGithub,
  faInstagram,
  faLinkedin,
  faTwitter,
  faNodeJs,
  faSymfony,
  faReact,
  faReacteurope,
  faAngular,
  faPhp,
  faAndroid,
  faFigma,
  faApple,
  faNode
} from '@fortawesome/free-brands-svg-icons';

// @ts-ignore the wrong typing
library.add([
  faEnvelope,
  faLinkedin,
  faInstagram,
  faGithub,
  faTwitter,
  faNodeJs,
  faNode,
  faSymfony,
  faReact,
  faReacteurope,
  faAngular,
  faPhp,
  faAndroid,
  faApple,
  faQuestion,
  faFigma,
  faClose,
  faStar,
]);
