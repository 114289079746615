import React, {useEffect} from 'react';
import Footer from '../footer/footer';
import {Header} from '../header/header';
import './layout.scss';
import {Helmet, useI18next} from 'gatsby-plugin-react-i18next';
import {useSiteMetadata} from '../../hooks/use-site-metadata';
import {useTranslation} from 'gatsby-plugin-react-i18next';

type LayoutProps = {
  children: React.ReactChild,
  pageTitle: string
}

export default function Layout({ children, pageTitle }: LayoutProps) {
  const { languages, language } = useI18next();
  const { title, titleTemplate } = useSiteMetadata();
  const { t } = useTranslation();

  useEffect(() => {
  }, languages);

  return (
    <>
      <Helmet
        titleTemplate={t('title_template')}
        defaultTitle={title}
      >
        <html lang={language}/>
        <meta charSet="utf-8"/>
        <title itemProp="name" lang={language}>
          {pageTitle}
        </title>
      </Helmet>
      <Header/>
      <div className={'content'}>
        {children}
      </div>
      <Footer/>
    </>
  );
}
