import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import './hero.scss';

type HeroProps = {
  children?: React.ReactChild
  size: 'small' | 'normal'
}
const Hero = ({ children, size }: HeroProps) => {
  return (
    <div className={`hero-container hero-container--${size}`}>
      {children}
      <div className={`hero hero--${size}`}>
        <div className={'plant'}>
          <div className={'plant_start'}>
            <StaticImage objectPosition={'top'} backgroundColor={'transparent'} src="../../assets/images/plant-start.jpg" alt="Hero background image start"/>
          </div>
          <div className={'plant_end'}>
            <StaticImage objectPosition={'top'} backgroundColor={'transparent'} src="../../assets/images/plant-end.jpg" alt="Hero background image end"/>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Hero;
