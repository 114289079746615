import {Link} from 'gatsby';
import * as React from 'react';
import {useEffect, useState} from 'react';
import './header.scss';
// @ts-ignore
import MenuIcon from './../../assets/images/menu.svg';
// @ts-ignore
import Logo from './../../assets/images/logo.svg';
import {LanguageSelector} from '../language-selector/language-selector';
import {Menu} from '../menu/menu';
import {useSiteMetadata} from '../../hooks/use-site-metadata';

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { title, menuLinks } = useSiteMetadata();

  useEffect(() => {
  }, [menuOpen]);

  return <>
    <header className={'header'}>
      <LanguageSelector/>
      <Link className={'logo'} to="/" title={`link to ${title}`}>
        <Logo/>
      </Link>
      <button className={'navigation_toggle'} onClick={() => {
        setMenuOpen(!menuOpen)
      }}>
        <MenuIcon/>
      </button>
    </header>
    <Menu menuOpen={menuOpen} onClose={() => setMenuOpen(!menuOpen)} links={menuLinks}/>
  </>;
};
